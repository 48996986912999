
<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      addButtTitle="Add Resource"
    ></lineFieldsArray>
    <input
      type="hidden"
      name="projectResources"
      :value="JSON.stringify(pairArray)"
    />
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
export default {
  components: {
    lineFieldsArray,
  },
  data: function () {
    //console.log(this.fieldAttributes);
    return { pairArray: [] };
  },
  props: ["field", "fieldAttributes", "result", "value"],
  created() {
    let pairArray = [];
    let value = {};
    if (typeof this.value != "undefined" && this.value != "") {
      value = JSON.parse(this.value);
    }
    if (typeof value.systemIds != "undefined") {
      value.systemIds.forEach((element) => {
        pairArray.push({
          resourceType: "activeProbe",
          resourceNameId: element,
        });
      });
    }

    if (typeof this.result.additional.assignedSimCards != "undefined") {
      this.result.additional.assignedSimCards.forEach((element) => {
        pairArray.push({
          resourceType: "simCard",
          resourceNameId: element,
        });
      });
    }
    this.pairArray = pairArray;
  },
  computed: {
    fields() {
      let fields = {
        resourceType: {
          type: "combobox",
          name: "Type",
          // associatedOption: this.result["additional"]["allowedActiveProbes"],
          associatedOption: [
            ["", ""],
            ["activeProbe", "Active Probe"],
            ["simCard", "SIM-Card"],
          ],
        },
        resourceNameId: {
          type: "combobox",
          name: "Name",
          inputFieldFormatter: "projectResources",
          availableSimCards: this.result["additional"]["availableSimCards"],
          allowedActiveProbes: this.result["additional"]["allowedActiveProbes"],
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
  },
  methods: {
    checkInput(value) {
      this.pairArray = [...value];
    },
  },
};
</script>